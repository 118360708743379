import * as React from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage } from '@src/pages/withBasePage';
import { actionAppUpdateState, SantaAppState } from '@src/state';
import { validateField } from '@src/utils/validators';
import { store } from '@src/state/store';
import { RegisterInput } from '@lub0v/santa-utils';
import {
  ButtonSocial,
  ButtonSocialGroup,
  ColumnCenter,
  Divider,
  Hint,
  Input,
} from '@lub0v/ui-components';
import { socialLogin, telegramLogin, updateStoreEmail } from '@src/pages/auth/auth';
import { validators } from '@src/components';
interface RegisterComponentProps extends BasePageProps {
  register: RegisterInput;
  onBtnClick?: () => void;
}

function RegisterComponentInternal(props: RegisterComponentProps) {
  return (
    <ColumnCenter translator={props.translator} style={props.style} alignCenter>
      <ButtonSocialGroup style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
        <ButtonSocial
          type="google"
          onClick={() => {
            if (props.onBtnClick) {
              props.onBtnClick();
            }
            socialLogin('google', props.register);
          }}
        >
          register_with_google
        </ButtonSocial>
        <ButtonSocial
          type="vk"
          onClick={() => {
            if (props.onBtnClick) {
              props.onBtnClick();
            }
            socialLogin('vk', props.register);
          }}
        >
          register_with_vk
        </ButtonSocial>
        <ButtonSocial
          type="tg"
          onClick={() => {
            telegramLogin(props.register.redirect, () => {});
          }}
        >
          register_with_tg
        </ButtonSocial>
      </ButtonSocialGroup>
      <Divider>or</Divider>
      <Input
        label="your_name"
        value={props.register.username}
        validators={[validateField(props.register, 'username'), validators.required()]}
        onChange={(username) => {
          store.dispatch(actionAppUpdateState({ register: { username } }));
        }}
      />
      <Input
        label="your_email"
        name="email"
        noBottomText
        value={props.register.email}
        validators={[validateField(props.register, 'email'), validators.required()]}
        onChange={(email) => {
          updateStoreEmail(email);
        }}
      />
      <Hint center>we_will_send_register_link</Hint>
    </ColumnCenter>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    register: state.register,
  };
}

export const RegisterComponent = connect(mapStateToProps)(RegisterComponentInternal);
