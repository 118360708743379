import * as React from 'react';
import { getCookie } from '@src/utils/cookie';
import { isDotCom } from '@src/host';
import { SantaAppState } from '@src/state';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { reachGoalYaMetrika } from '@src/utils/metrika';

function extractYandexMarketLink(text: string): string | null {
  const regex = /https:\/\/market\.yandex\.ru\/[^\s]*/;
  const match = text.match(regex);
  return match ? match[0] : null;
}

const YandexWishesBtnComponent = ({ text, title }: { text; title: string }) => {
  //  ulock val: U2FsdGVkX1%2FLj9lLq46TpIKVyGVodk6%2BIOPhr20%2FPFw%3D
  if (!text || isDotCom() || getCookie('yandex_ad') !== 'unlock') {
    return null;
  }
  // disable button
  return null;

  const link = extractYandexMarketLink(text);
  if (!link) {
    return null;
  }
  useEffect(() => {
    reachGoalYaMetrika('YandexWishesBtnShown');
  }, []);
  return (
    <a href={link} target="_blank">
      <button
        style={{
          background: '#FF4112',
          borderRadius: '6rem',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          height: '3rem',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '.5rem auto',
          textAlign: 'center',
          outline: 'none',
          padding: '.3rem 1.2rem',
          position: 'relative',
          userSelect: 'none',
          whiteSpace: 'nowrap',
          color: '#FFF',
          border: 'none',
          fontWeight: 'bold',
        }}
      >
        <img
          src={'https://santa-secret.b-cdn.net/yandex_market_btn_logo.png'}
          style={{ marginRight: '0.5rem' }}
        />
        {title}
      </button>
    </a>
  );
};

function mapStateToProps(state: SantaAppState) {
  return {
    mobile: state.css.mobile,
  };
}

export const YandexWishesBtn = connect(mapStateToProps)(YandexWishesBtnComponent);
