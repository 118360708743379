import * as React from 'react';

import { _, linkSeparateTab, linkSmall } from '@src/translations/utils.translations';

export const RANDOMIZER_TRANSLATIONS = {
  randomizer_tip: _(
    () => (
      <>
        Здесь вы можете провести случайную жеребьевку между участниками, не приглашая их на сайт.
        После проведения каждый участник получит письмо с именем подопечного, а вы — письмо со
        ссылкой на результаты жеребьевки. <b>Ограничение — 100 участников</b>.
        <br />
        <br />
        Участники не смогут написать свои пожелания. Если вы хотите писать пожелания, оповещать об
        отправлении подарков и влиять на жеребьевку - {linkSmall('/box/new', 'создайте коробку')}.
      </>
    ),
    <>
      Here you can conduct a random draw among participants without inviting them to the website.
      After the draw, each participant will receive an email with the name of their giftee, and you
      will receive an email with a link to the draw results. <b>Limit — 100 participants</b>.
      <br />
      <br />
      Participants will not be able to write their wishes. If you want to write wishes, notify about
      the sending of gifts, and influence the draw - {linkSmall('/box/new', 'create a box')}.
    </>,
  ),
  i_will_also_participate: _('Я тоже участвую', 'I will also participate'),
  i_will_also_participate_hint: _(
    'Включите опцию, если вы тоже планируете участвовать в жеребьевке — вам автоматически будет создана запись участника',
    'Enable this option if you plan to participate in the draw — a participant entry will be automatically created for you',
  ),
  user_name: _('Имя участника', "Participant's name"),
  org_name: _('Ваше Имя', 'Your name'),
  org_email: _('Ваш email', 'Your email'),
  user_email: _('Email участника', "Participant's Email"),
  randomizer_many_users: _(
    () => (
      <>
        Нужно пригласить много участников? Создайте таблицу с участниками, например, в Google Sheets
        (имя в первом столбце, email во втором) скопируйте всё и вставьте в первую ячейку ниже.{' '}
        <b>Ограничение — 100 человек</b>
      </>
    ),
    <>
      Need to invite a lot of participants? Create a table with participants, for example, in Google
      sheets, copy everything and paste it into the first cell below. <b>Limit — 100 people</b>
    </>,
  ),
  randomizer_confirm_users: _(
    'Проверьте, все ли участники на месте. После нажатия на кнопку "Продолжить" мы отправим письма участникам.',
    "Check if all participants are present. After clicking the 'Continue' button, we will send emails to the participants.",
  ),
  randomizer_done: _('Жеребьевка проведена!', 'The draw has been conducted!'),
  randomizer_done_hint: _(
    'Письма с подопечными отправляются участникам, а вам отправлено письмо со ссылкой на результаты жеребьевки.',
    'Emails with giftee details are sent to the participants, and you have been sent an email with a link to the draw results.',
  ),
  randomizer_open_ward: _('Узнать подопечного', 'Find out giftee'),
  randomizer_policy_agreement_hint: _(
    <>
      Продолжая, вы даете согласие на {linkSeparateTab('/policy', 'обработку персональных данных.')}
    </>,
    <>
      By continuing, you consent to the {linkSeparateTab('/policy', 'processing of personal data.')}
    </>,
  ),
  randomizer_open_santa: _('Узнать Тайного Санту', 'Find out Secret Santa'),
  randomizer_step: _(
    (step) => `Шаг ${step} из 3`,
    (step) => `Step ${step} of 3`,
  ),
  randomizer_organizer: _('Организатор', 'Organizer'),
  randomizer_participants: _('Участники', 'Participants'),
};
