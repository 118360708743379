import { BoxCreateInput, BoxInfo, CardInfo, CashCurrency } from '@lub0v/santa-utils';
import { BOX_PICTURES } from '@src/components';
import { SantaAppState, store } from '@src/state';
import { t, TRANSLATIONS } from '@src/translations';

export const BOX_PATH = '/box/:key([0-9a-zA-Z\\_\\-&]+)';

export function getEditBoxData(box: BoxInfo): BoxCreateInput {
  const editBox = new BoxCreateInput();
  editBox.key = box.box.key;
  editBox.picture = box.box.picture;
  editBox.name = box.box.name;
  editBox.useCashLimit = box.box.useCashLimit;
  editBox.cashLimit = box.box.cashLimit;
  editBox.cashLimitMin = box.box.cashLimitMin;
  editBox.cashLimitCurrency = box.box.cashLimitCurrency as CashCurrency;
  editBox.usePost = box.box.usePost;
  editBox.useWish = box.box.useWish;
  editBox.useNames = box.box.useNames;
  editBox.isPhoneRequired = box.box.isPhoneRequired;
  return editBox;
}

export function getBoxPictures(box: BoxInfo) {
  const pictures = [...BOX_PICTURES];
  const ind = pictures.indexOf(box.box.picture);
  pictures.splice(ind, 1);
  return [box.box.picture, ...pictures];
}

export function getBoxPath(path: string): string {
  const { box } = store.getState() as SantaAppState;
  if (!box.info) {
    return '';
  }
  return `/box/${box.info.box.key}${path}`;
}

export function getWardOptions(card: CardInfo, cards: CardInfo[]) {
  const DRAW_SELECT_WARD = t(TRANSLATIONS.draw_select_ward);
  // TODO recover from state where predefined ward is deleted but still in the card wardId

  const options = cards
    .filter((c) => +c.id !== +card.id)
    .filter((c) => +c.id === +card.wardId || !cards.some((crd) => +crd.wardId === +c.id))
    .map((c) => ({
      key: c.id,
      value: c.username,
    }));
  return [{ key: '0', value: DRAW_SELECT_WARD }, ...options];
}
