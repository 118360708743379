import * as React from 'react';
import { _, link } from '@src/translations/utils.translations';

export const NEW_BOX_TRANSLATIONS = {
  new_box_step0_title: _('Давайте познакомимся', "Let's get acquainted"),
  new_box_step_title: _('Шаг {step} из 5', 'Step {step} of 5'),
  new_box_step_name_title: _('Придумайте название коробке', 'Come up with a name for the box'),
  new_box_step_picture_title: _('Выберите обложку', 'Choose a cover'),
  new_box_step_cost_title: _('Стоимость подарков', 'Cost of gifts'),
  new_box_step_cost_switch_title: _('Ограничить стоимость подарков', 'Limit the cost of gifts'),
  new_box_step_cost_switch_hint: _(
    'При включенной опции участникам будет показано ограничение, которому они должны будут следовать. Ограничение будет показано на странице подопечного',
    'When this option is enabled, participants will be shown a limit which they must follow. The limit will be displayed on the recipient’s page',
  ),
  new_box_step_cash_limit_min: _('От', 'Min'),
  new_box_step_cash_limit_max: _('До', 'Max'),
  new_box_step_sum: _('Сумма', 'Amount'),
  new_box_step_info_title: _('Дополнительные настройки', 'Additional Settings'),
  new_box_box_name: _('Название коробки', 'Box Name'),
  new_box_box_name_hint: _(
    'Вы сможете поменять название позже в настройках',
    'You can change the name later in the settings',
  ),
  new_box_box_key: _('Идентификатор', 'Identifier'),
  new_box_step_info_use_wish: _('Пожелания по подаркам', 'Gift Preferences'),
  new_box_step_info_use_wish_hint: _(
    'При включенной опции участники смогут написать пожелания к подарку.',
    'When this option is enabled, participants will be able to write their gift preferences.',
  ),
  new_box_step_info_use_post: _('Почтовый адрес', 'Postal Address'),
  new_box_step_info_use_post_hint: _(
    'При включенной опции участникам нужно будет указать свой почтовый адрес в карточке',
    'When this option is enabled, participants will need to specify their postal address on the card',
  ),
  new_box_register_email_sent: _('Письмо отправлено!', 'Email Sent!'),
  new_box_register_email_sent_hint: _(
    'Вам на почту было отправлено письмо регистрации. Перейдите по ссылке из письма, чтобы продолжить.',
    'A registration email has been sent to you. Follow the link in the email to continue.',
  ),
  new_box_step_info_use_names: _('Показывать имена участников', "Show Participants' Names"),
  new_box_step_info_phone_required: _('Номер телефона', 'Phone Number'),
  new_box_step_info_use_names_hint: _(
    <>
      При включенной опции участники будут видеть имена других игроков. Когда опция выключена,
      участники будут видеть только аватарки игроков, но будут видеть имя своего подопечного (и
      только его). Организатор будет видеть имена игроков вне зависимости от состояния опции.
    </>,
    <>
      When this option is enabled, participants will see the names of other players. When the option
      is turned off, participants will only see the avatars of the players, but will see the name of
      their recipient (and only theirs). The organizer will see the names of the players regardless
      of the option's status.
    </>,
  ),
  new_box_step_info_phone_required_hint: _(
    <>
      При включенной опции участники будут обязаны оставить свой номер телефона. Если опция
      выключена, номер телефона не обязателен.
    </>,
    <>
      When this option is enabled, participants will be required to leave their phone number. If the
      option is turned off, the phone number is not mandatory.
    </>,
  ),
  new_box_step0_tip: _(
    <>
      Для того, чтобы вы могли создать коробку и приглашать участников, необходимо
      зарегистрироваться.
      <br />
      <br />
      Если вы уже имеете аккаунт на сайте - {link('/login', 'войдите')}.
    </>,
    <>
      To create a box and invite participants, you need to register.
      <br />
      <br />
      If you already have an account on the site - {link('/login', 'log in')}.
    </>,
  ),
  new_box_box_key_hint: _(
    <>
      Придумайте уникальный идентификатор для коробки, который будет указываться в ссылке. Например:
      https://santa-secret.ru/box/<b>santa2020</b>
      <br />
      <br />
      <b>Важно:</b> Идентификатор нельзя будет изменить после создания коробки.
    </>,
    <>
      Create a unique identifier for the box, which will be included in the link. For example:
      https://santa-secret.ru/box/<b>santa2020</b>
      <br />
      <br />
      <b>Important:</b> The identifier cannot be changed after the box is created.
    </>,
  ),
};
