import { BoxPageProps } from '@src/pages/box/BoxPage';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Chat, ChatMessage, ColumnStart, Icon } from '@src/components';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState } from '@src/state';
import { MessageInfo, MessagesInfoOutput } from '@lub0v/santa-utils';
import * as moment from 'moment';
import { NoticeChatWithWard } from '@src/notices/NoticeChatWithWard';
import { NoticeEmptyChat } from '@src/notices/NoticeEmptyChat';
import { FileUpload } from '@src/components/FileUpload';
import { isDotCom } from '@src/host';

export function ChatWithWard(props: BoxPageProps & { onClose: () => void }) {
  const [message, setMessage] = useState('');
  const fileUpload = useRef(null);

  const { key } = props.box.info.box;

  const setMessages = (r: MessagesInfoOutput) => {
    if (r.ok) {
      dispatchActionAppUpdateState({ box: { wardMessages: r.data } });
    }
  };

  useEffect(() => {
    santaClient.getWardMessages({ key }).then(setMessages);
  }, []);

  if (!props.box.wardChatOpened && !(props.box.wardMessages && props.box.wardMessages.length)) {
    return <NoticeChatWithWard {...props} />;
  }

  const deleteMessage = async (m: MessageInfo) => {
    await santaClient.updateWardMessage({ id: m.id, message: '', key }).then(setMessages);
  };

  const sendMessage = async () => {
    const messageToSend = message;
    setMessage('');
    const r = await santaClient.writeToWard({ key, message: messageToSend, isGiftSent: false });
    if (r.ok) {
      setMessages(r);
    } else {
      setMessage(messageToSend);
    }
  };

  const onFileStateChanged = async (state, file) => {
    if (state === 'uploaded') {
      const r = await santaClient.writeToWard({
        key,
        message: file.filename,
        attachment: file,
        isGiftSent: false,
      });
      if (r.ok) {
        setMessages(r);
        setMessage('');
      }
    }
  };

  const messages = props.box.wardMessages;

  return (
    <ColumnStart
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr auto',
        height: '100%',
      }}
    >
      <Chat
        onClose={props.onClose}
        disabled={props.box.info.box.isArchived}
        translator={props.translator}
        formatDate={(ts) => moment(ts).format('DD MMMM YYYY')}
        formatTime={(ts) => moment(ts).format('hh:mm:ss')}
        title={'ward_chat_title'}
        locale={isDotCom() ? 'en' : 'ru'}
        value={message}
        onChange={setMessage}
        onSend={sendMessage}
        placeholder={'ward_chat_placeholder'}
        empty={<NoticeEmptyChat {...props} />}
        icon={
          <Icon
            type={'attach'}
            style={{ width: '2rem' }}
            onClick={() => {
              fileUpload.current.open();
            }}
          />
        }
      >
        {messages
          ? messages.map((m: MessageInfo) => {
              const name = m.isAutomatic
                ? 'Автоматическое сообщение'
                : m.isYou
                ? 'Вы'
                : props.box.ward.username;
              return (
                <ChatMessage
                  key={m.timestamp}
                  time={m.timestamp}
                  isYou={m.isYou}
                  isAutomatic={m.isAutomatic}
                  attachment={m.attachment}
                  deleted={m.active ? '' : 'Сообщение было удалено...'}
                  name={name}
                  icon={
                    m.isGiftReceived ? (
                      <Icon type={'gift-received'} />
                    ) : m.isGiftSent ? (
                      <Icon type={'gift-sent'} />
                    ) : null
                  }
                  onDelete={() => deleteMessage(m)}
                >
                  {m.message}
                </ChatMessage>
              );
            })
          : null}
      </Chat>
      <FileUpload ref={fileUpload} onChange={onFileStateChanged} />
    </ColumnStart>
  );
}
