import * as React from 'react';

import { _ } from '@src/translations/utils.translations';

export const NOTICES_TRANSLATIONS = {
  notice_not_authorized_title: _('Эта страница вам недоступна', 'This Page is Unavailable to You'),
  notice_not_authorized_hint: _(
    'Чтобы просматривать эту страницу, вам необходимо войти на сайт',
    'To view this page, you need to log into the site',
  ),
  notice_donate_success_title: _(
    'Большое спасибо за ваше пожертвование! ',
    'Thank you very much for your donation!',
  ),
  notice_donate_success_hint: _(
    'Благодаря таким людям, как вы, сайт продолжает существовать и развиваться!',
    'Thanks to people like you, the site continues to exist and develop!',
  ),
  notice_its_chat_with_santa_title: _(
    'Это — анонимный чат с вашим Тайным Сантой',
    'This is an Anonymous Chat with Your Secret Santa',
  ),
  notice_its_chat_with_santa_hint: _(
    'Ваш Тайный Санта сможет узнавать через этот чат необходимую от вас информацию, а вы сможете сообщить о получении подарка. Вы не узнаете, кто ваш Тайный Санта, так как его имя будет скрыто.',
    'Your Secret Santa can obtain necessary information from you through this chat, and you can notify about receiving the gift. You will not know who your Secret Santa is, as their name will be hidden.',
  ),
  notice_open_chat_button: _('Открыть чат', 'Open Chat'),
  notice_its_chat_with_ward_title: _(
    'Это — анонимный чат с вашим подопечным',
    'This is an anonymous Chat with Your Ward',
  ),
  notice_its_chat_with_ward_hint: _(
    'Здесь вы можете уточнять информацию от вашего подопечного, а так же сообщить об отправке подарка. Чат анонимный, но будьте аккуратны и постарайтесь не выдать себя!',
    'Here you can clarify information from your ward and also notify about sending the gift. Chat is anonymous, but be careful and try not to reveal yourself!',
  ),
  notice_empty_chat_title: _('Сообщений пока что нет', 'No Messages Yet'),
  notice_empty_chat_hint: _(
    'Сообщений пока что нет. Отправьте первое сообщение, если необходимо. Это анонимно.',
    'No messages yet. Send the first message if necessary. Chat is anonymous.',
  ),
  notice_maintenance_title: _('Ведутся работы', 'Maintenance in Progress'),
  notice_maintenance_hint: _(
    'Мы обновляем сервера, чтобы сайт смог помогать большему количеству Тайных Сант. Приносим извинения за доставленные неудобства. Сайт будет доступен в течение двух часов.',
    'We are updating servers so that the site can assist more Secret Santas. We apologize for any inconvenience caused. The site will be available within two hours.',
  ),
  notice_site_temporary_off_title: _('Сайт временно недоступен', 'Site Temporarily Unavailable'),
  notice_site_temporary_off: _(
    'Мы обновляем сервера, чтобы сайт смог помогать большему количеству Тайных Сант. Приносим извинения за доставленные неудобства. Сайт будет доступен в течение часа.',
    'Technical work is underway. The site will be available within an hour. We apologize for the inconvenience.',
  ),
  notice_its_future_chat_with_santa_hint: _(
    <>
      <br />
      <br />
      На данный момент у вас нет Тайного Санты. Чат станет доступен после проведения жеребьевки.
    </>,
    <>
      <br />
      <br />
      You currently do not have a Secret Santa. The chat will become available after the draw is
      conducted.
    </>,
  ),
};
