import * as React from 'react';

import { _ } from '@src/translations/utils.translations';

export const COMMON_TRANSLATIONS = {
  or: _('или', 'or'),
  your_name: _('Ваше имя', 'Your name'),
  your_email: _('Ваш email', 'Email'),
  your_password: _('Пароль', 'Password'),
  boxes: _('Коробки', 'Boxes'),
  notifications: _('Уведомления', 'Notifications'),
  back_to_home: _('Вернуться на главную', 'Back to the main page'),
  save: _('Сохранить', 'Save'),
  cancel: _('Отмена', 'Cancel'),
  currency_title: _('Валюта', 'Currency'),
  currency: {
    rub: {
      title: _('Рубль', 'Ruble'),
      num: _('{count рубл|ь|я|ей}', '{count ruble||s|s}'),
    },
    kzt: {
      title: _('Тенге', 'Tenge'),
      num: _('{count тенге|||}', '{count tenge||s|s}'),
    },
    usd: {
      title: _('Доллар', 'USD'),
      num: _('{count доллар||а|ов}', '{count usd|||}'),
    },
    eur: {
      title: _('Евро', 'Euro'),
      num: _('{count евро|||}', '{count euro||s|s}'),
    },
    uah: {
      title: _('Гривна', 'Hryvnia'),
      num: _('{count грив|ну|ны|ен}', '{count hryvnia||s|s}'),
    },
    byn: {
      title: _('Белорусский рубль', 'Belarusian Ruble'),
      num: _('{count белорусск|ий рубль|их рубля|их рублей}', '{count Belarusian ruble|||s}'),
    },
    sec: {
      title: _('Шведская крона', 'Swedish krona'),
      num: _('{count шведск|ая крона|ие кроны|их крон}', '{count swedish krona|||}'),
    },
    amd: {
      title: _('Армянский драм', 'Armenian dram'),
      num: _('{count армянск|ий драм|их драма|их драмов}', '{count armenian dram|||}'),
    },
  },
  to_confirm_enter: _(
    (word) => (
      <>
        Для подтверждения введите: <b>{word}</b>
      </>
    ),
    (word) => (
      <>
        To confirm enter: <b>{word}</b>
      </>
    ),
  ),
  here: _('cюда', 'here'),
  no_notifications: _('Уведомлений нет', 'No notifications'),
  mark_as_read: _('Отметить все как прочитанные', 'Mark all as read'),
  all_notifications_read: _('Новых уведомлений нет', 'No new notifications'),
  ward: _('Подопечный', 'Giftee'),
  new_ward: _('Новый подопечный', 'New giftee'),
  santa: _('Тайный Санта', 'Secret Santa'),
  yes: _('Да', 'Yes'),
  no: _('Нет', 'No'),
  recipient: _('Получатель', 'Recipient'),
  boxberry_will_send: _(
    'доставит посылку c 15% скидкой по коду',
    'will deliver your parcel with an 15% discount using the code ',
  ),
  boxberry_more: _('подробнее', 'learn more'),
  tel: _('Тел.: ', 'Phone: '),
};
