import * as React from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import {
  ButtonMain,
  ButtonSecondary,
  ColumnCenter,
  HomePageContainer,
  RowCenter,
  SantaLink,
  SimpleLink,
  TextH3Medium,
  TextH3Semi,
  TextRegular,
} from '@src/components';
import { FAQHome } from '@src/pages/FAQHome';
import { t } from '@src/translations';
import { BoxberryAd } from '@src/pages/box/ward/BoxberryAd';
import { isDotCom } from '@src/host';
import { reachGoalYaMetrika } from '@src/utils/metrika';
import { AdLookIBV } from '@src/components/AdLookIBV';

function HomePageComponent(props: BasePageProps & any) {
  return (
    <div className={props.className}>
      <section style={{ marginTop: '2rem' }}>
        <HomePageContainer
          translator={props.translator}
          lang={props.lang}
          buttonLeft={
            <SantaLink to="/box/new">
              <ButtonMain
                translator={props.translator}
                style={{ width: '100%', minWidth: '15rem', maxWidth: '25rem' }}
              >
                create_box
              </ButtonMain>
            </SantaLink>
          }
          buttonRight={
            <SantaLink to="/randomizer">
              <ButtonSecondary
                translator={props.translator}
                style={{ width: '100%', minWidth: '15rem', maxWidth: '25rem' }}
              >
                quick_draw
              </ButtonSecondary>
            </SantaLink>
          }
        >
          <SantaLink to="/box/new">
            <ButtonMain style={{ maxWidth: '15rem', margin: 'auto' }}>create_box</ButtonMain>
          </SantaLink>
          <FAQHome {...props} />
          <ColumnCenter style={{ marginBottom: '2rem' }}>
            <BoxberryAd />
          </ColumnCenter>
          <AdLookIBV numAdsDesktop={4} numAdsMobile={1} />
        </HomePageContainer>
      </section>
    </div>
  );
}

export const HomePage = connect(mapStateToPropsBasePage)(
  withBasePage<BasePageProps>(HomePageComponent, {
    headerHome: true,
    headerFixed: true,
    counter: true,
  }),
);
