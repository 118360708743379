import {
  ButtonService,
  ColumnStart,
  HintError,
  Input,
  RowEnd,
  Validator,
} from '@lub0v/ui-components';
import * as React from 'react';
import { useState } from 'react';
import { t, TRANSLATIONS } from '@src/translations';

interface DeleteProps {
  hint?: string;
  button: string;
  confirmDeleteWord: string;
  onClick: () => void;
  children?: any;
  translator: any;
  error?: string;
}

export function DeleteSection(props: DeleteProps) {
  const [confirmDelete, setConfirmDelete] = useState('');

  const CONFIRM_WORD = t(TRANSLATIONS[props.confirmDeleteWord]);
  const validateConfirmDelete: Validator = (val: string) => {
    if (val.toLowerCase().trim() === CONFIRM_WORD.toLowerCase().trim()) {
      return null;
    }
    return 'validations.confirm_incorrect';
  };

  return (
    <>
      {props.children}
      <Input
        translator={props.translator}
        label={t(TRANSLATIONS.to_confirm_enter, {
          word: CONFIRM_WORD,
        })}
        value={confirmDelete}
        validators={[validateConfirmDelete]}
        onChange={setConfirmDelete}
      />
      <RowEnd>
        {confirmDelete.toLowerCase().trim() === CONFIRM_WORD.toLowerCase().trim() ? (
          <ColumnStart>
            <RowEnd>
              <ButtonService
                onClick={props.onClick}
                style={{ marginRight: 0 }}
                translator={props.translator}
              >
                {props.button}
              </ButtonService>
            </RowEnd>
            <HintError errors={[props.error]} translator={props.translator} />
          </ColumnStart>
        ) : null}
      </RowEnd>
    </>
  );
}
