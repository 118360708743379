import * as React from 'react';

import { _ } from '@src/translations/utils.translations';

export const ACCOUNT_TRANSLATIONS = {
  account_profile_settings: _('Настройки профиля', 'Profile settings'),
  account_personal_data: _('Личные данные', 'Personal data'),
  account_password: _('Пароль', 'Password'),
  account_social_links: _('Социальные сети', 'Social networks'),
  account_delete: _('Удаление профиля', 'Account deletion'),
  account_confirm_delete_word: _('Удалить профиль', 'Delete account'),
  account_delete_button: _('Удалить профиль', 'Delete account'),
  account_delete_hint: _(
    <>
      <b>Важно:</b> прежде чем удалить аккаунт, вам необходимо удалить карточки из всех активных
      розыгрышей.
    </>,
    <>
      <b>Important:</b> Before deleting your account, you need to remove the cards from all active
      draws
    </>,
  ),
  account_username_saved: _('Ваше имя сохранено', 'Your name is saved'),
  account_email_saved: _('Ваш email сохранен', 'Your email is saved'),
  account_password_saved: _('Ваш пароль сохранен', 'Your password is saved'),
  link_with_google: _('Привязать аккаунт Google', 'Link Google account'),
  link_with_vk: _('Привязать аккаунт Вконтакте', 'Link Vkontakte account'),
  link_with_fb: _('Привязать аккаунт Facebook', 'Link Facebook account'),
  link_with_tg: _('Привязать аккаунт Telegram', 'Link Telegram account'),
  account_verify_email_link_sent: _(
    (email) => (
      <>
        На почтовый адрес <b>{email}</b> было отправлено письмо.
        <br />
        Пожалуйста, перейдите по ссылке из письма, чтобы подтвердить адрес.
      </>
    ),
    (email) => (
      <>
        An email has been sent to the email address <b>{email}</b>.<br />
        Please click the link in the email to confirm your address.
      </>
    ),
  ),

  account_notifications_settins: _('Уведомления', 'Notifications'),

  account_notifications_email_title: _('Email', 'Email'),
  account_notifications_email_hint: _(
    'При включенной опции уведомления будут приходить вам на email адрес, указанный выше',
    'With the notification option enabled, you will receive notifications at the email address provided above',
  ),

  account_notifications_telegram_title: _('Telegram', 'Telegram'),
  account_notifications_telegram_hint: _(
    'При включенной опции уведомления будут приходить вам в телеграм чат с нашим ботом',
    'With the notification option enabled, notifications will be sent to you in the Telegram chat with our bot',
  ),

  account_notifications_vk_title: _('Вконтакте', 'VKontakte'),
  account_notifications_vk_hint: _(
    'При включенной опции уведомления будут приходить вам Вконтакте от имени сообщества нашего сайта',
    "With the notification option enabled, you will receive notifications on VKontakte from our website's community",
  ),
  account_logout: _('Выйти из профиля', 'Logout'),
};
