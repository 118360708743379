import * as React from 'react';
import { _ } from '@src/translations/utils.translations';
import { RowCenter, SimpleLink, TextMedium } from '@src/components';

export const BOX_TRANSLATIONS = {
  box_organizer: _('Организатор: ', 'Organizer: '),
  box_participants_count: _('Участников: ', 'Participants: '),
  box_is_archived: _('коробка в архиве', 'box in the archive'),
  box_go_to_participants: _('Перейти к списку участников', 'Go to participants list'),
  box_org_menu_hint: _(
    'Это меню доступно только вам как организатору коробки',
    'This menu is available only to you as the box organizer',
  ),
  box_org_menu_add_users: _('Добавить участников', 'Add participants'),
  box_org_menu_draw: _('Жеребьевка', 'Draw'),
  box_org_menu_draw_active: _('Проведена', 'Active'),
  box_org_menu_santas: _('Кто чей санта?', 'Who is whose Santa?'),
  box_org_menu_settings: _('Настройки коробки', 'Box settings'),
  box_org_menu_table: _(
    'Кто чей санта? Таблица участников',
    'Who is whose Santa? Participants table',
  ),
  box_org_menu_delete: _('Архивация и удаление', 'Archive and delete'),
  no_participants: _('Пока что никого нет', 'No participants yet'),
  box_only_you_here: _('Вам не помешает хорошая компания', 'Good company never hurts'),
  box_only_you_here_hint: _(
    'Чтобы провести жеребьевку, вам необходимо добавить еще хотя бы двух друзей.',
    'To conduct a draw, you need to add at least two more friends.',
  ),
  no_participants_hint: _(
    'Чтобы начать игру, добавьте своих друзей',
    'To start the game, add your friends',
  ),
  add_participants: _('Добавить участников', 'Add participants'),
  box_invite_title: _('Пригласить участников', 'Invite participants'),
  box_invite_organizer_participating: _('Ваше участие', 'Your participation'),
  box_invite_create_org_card: _('Создать карточку участника', 'Create a participant card'),
  box_invite_org_card_created_hint: _(
    <>
      Вы уже создали себе карточку.
      <br />
      <br />
      <b>Важно:</b> не нужно вписывать свои данные в список добавляемых участников ниже.
    </>,
    <>
      You've already created your card.
      <br />
      <br />
      <b>Important:</b> You don't need to enter your information in the list of added participants
      below.
    </>,
  ),
  box_invite_create_org_card_hint: _(
    <>
      Если вы тоже планируете участвовать в жеребьевке, создайте себе карточку участника.
      <br />
      <b>Важно:</b> не нужно вписывать свои данные в список добавляемых участников ниже.
    </>,
    <>
      If you also plan to participate in the draw, create a participant card for yourself.
      <br />
      <b>Important:</b> You don't need to enter your information in the list of added participants
      below.
    </>,
  ),
  box_invite_users_with_link: _('Пригласить по ссылке', 'Invite via link'),
  box_invite_users: _('Добавить участников вручную', 'Add participants manually'),
  box_invite_users_button: _('Пригласить участников', 'Invite participants'),
  box_invite_users_invited_after_draw: _(
    <>
      Карточки участников успешно созданы.
      <br />
      <br /> Если необходимо, вы можете
    </>,
    <>
      Participant cards have been successfully created.
      <br />
      <br /> If necessary, you can
    </>,
  ),
  box_invite_users_invited: _(
    <>
      Карточки участников успешно созданы и приглашения уже отправляются.
      <br />
      <br /> Если необходимо, вы можете
    </>,
    <>
      Participant cards have been successfully created, and invitations are already being sent.
      <br />
      <br /> If necessary, you can
    </>,
  ),
  box_invite_more_users: _('добавить еще участников.', 'add more participants.'),
  box_invite_link_copied: _('Ссылка скопирована', 'Link copied'),
  box_invite_by_link_disabled: _(
    <>
      Жеребьевка уже проведена. Пригласить по ссылке можно только до проведения жеребьевки. Если вы
      хотите добавить участников после - используйте таблицу ниже.
    </>,
    <>
      Draw has already been conducted. You can invite people by invitation link only before the draw
      has been conducted. If you need to invite people after that - use the table below.
    </>,
  ),
  box_invite_by_link_tip: _(
    (inviteLink: string) => (
      <>
        Скопируйте ссылку ниже и отправьте её своим друзьям. После перехода по ссылке, участники
        смогут создать карточки для участия самостоятельно.
        <br />
        <br />
        Ccылка-приглашение (кликните, чтобы скопировать): <br />
        <b>{inviteLink}</b>
      </>
    ),
    (inviteLink: string) => (
      <>
        Copy the link below and send it to your friends. After clicking the link, participants will
        be able to create their own participant cards.
        <br />
        <br />
        Invitation link (click to copy): <br />
        <b>{inviteLink}</b>
      </>
    ),
  ),
  box_invite_by_table_tip: _(
    <>
      Вы можете пригласить участников, введя их имена и почтовые адреса в таблицу ниже. Участникам
      отправятся приглашения и они должны будут перейти по ссылке из письма, чтобы подтвердить
      участие.
      <br />
      <br />
      Нужно пригласить много участников? Создайте таблицу с участниками, например, в Google sheets
      (имя в первом столбце, email во втором), скопируйте данные и вставьте в первую ячейку ниже.
      Ограничение — 100 участников за один раз.
    </>,
    <>
      You can invite participants by entering their names and email addresses in the table below.
      Invitations will be sent to participants, and they will need to click the link in the email to
      confirm their participation.
      <br />
      <br />
      Need to invite many participants? Create a table with participants, for example, in Google
      sheets, copy the data, and paste it into the first cell below. Limit: 100 participants at a
      time.
    </>,
  ),
  box_invite_after_draw: _(
    'Отправить приглашения вместе с результатами жеребьевки',
    'Send invitations along with the drawing results',
  ),
  box_invite_after_draw_hint: _('Что это?', 'What is this?'),
  box_invite_after_draw_hint_big: _(
    <>
      При включенной опции приглашенные участники будут сразу же зарегистрированы в игре, но письмо
      с приглашением им отправится только после проведения вами жеребьевки. Эта опция подходит,
      когда вы знаете весь список участников.
      <br />
      <br />C выключенной опцией приглашение отправится сразу же после нажатия на кнопку{' '}
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      "Пригласить участников". Приглашенные участники должны будут перейти по ссылке из письма,
      чтобы подтвердить участие, а после жеребьевки им отправится уведомление о том, что жеребьевка
      проведена.
    </>,
    <>
      With the invitation option enabled, invited participants will be registered in the game
      immediately, but the invitation email will be sent to them only after you conduct the drawing.
      This option is suitable when you have the complete list of participants.
      <br />
      <br />
      With the invitation option disabled, the invitation will be sent immediately after clicking
      the "Invite Participants" button. Invited participants will need to click the link in the
      email to confirm their participation, and after the drawing, they will receive a notification
      that the drawing has been conducted.
    </>,
  ),
  box_settings_title: _('Настройки коробки', 'Box Settings'),
  box_settings_save_button: _('Сохранить изменения', 'Save Changes'),
  box_settings_back_button: _('Назад к коробке', 'Back to Box'),
  box_settings_box_name: _('Название', 'Name'),
  box_settings_box_picture: _('Обложка', 'Cover'),
  box_settings_saved: _('Изменения успешно сохранены', 'Changes successfully saved'),
  box_delete_title: _('Архивация и удаление', 'Archiving and Deleting'),
  box_delete_archive_title: _('Архивировать коробку', 'Archive the box'),
  box_restore_title: _('Восстановить коробку', 'Restore the box'),
  box_delete_delete_title: _('Удалить коробку', 'Delete the box'),
  box_delete_confirm_archive_word: _('Архивировать коробку', 'Archive the box'),
  box_restore_confirm_archive_word: _('Восстановить коробку', 'Restore the box'),
  box_delete_confirm_delete_word: _('Удалить коробку', 'Delete the box'),
  box_delete_archive_button: _('Архивировать коробку', 'Archive the box'),
  box_restore_button: _('Восстановить коробку', 'Restore the box'),
  box_delete_delete_button: _('Удалить коробку', 'Delete the box'),
  box_invite_archived: _(
    'Вы не можете пригласить участников в коробку, которая находится в архиве',
    'You cannot invite participants to a box that is archived',
  ),
  box_delete_archived: _(
    'Вы не можете удалить коробку, которая находится в архиве',
    'You cannot delete a box that is archived',
  ),
  box_draw_archived: _(
    'Вы не можете провести жеребьевку в коробке, которая находится в архиве',
    'You cannot conduct a draw in a box that is archived',
  ),
  box_is_not_ready_for_draw: _(
    'В коробке еще недостаточно участников для проведения жеребьевки. ',
    'There are not enough participants in the box to conduct the draw. ',
  ),
  box_is_ready_for_draw: _(
    'В коробке уже достаточно участников для проведения жеребьевки. ',
    'There are already enough participants in the box to conduct the draw. ',
  ),
  box_admin_only_info: _(
    'Это сообщение показывается только вам как организатору коробки.',
    'This message is shown to you as the organizer of the box.',
  ),
  box_not_found_title: _('Коробка не найдена', 'Box Not Found'),
  box_not_found_hint: _(
    'Похоже, вы перешли по неверной ссылке для коробки.',
    'It seems you have followed an incorrect link for the box.',
  ),
  box_not_authorized_title: _('Нет доступа к этой коробке', 'No Access to the Box'),
  box_delete_archive_hint: _(
    <>
      Вы можете заархивировать коробку. Коробки в архиве доступны вам и участникам только для
      просмотра.
    </>,
    <>You can archive the box. Archived boxes are only viewable by you and participants.</>,
  ),
  box_restore_hint: _(
    <>Вы можете восстановить коробку из архива</>,
    <>You can restore the box from archive.</>,
  ),
  box_delete_delete_hint: _(
    <>
      <b>Важно:</b> вы не сможете вернуть коробку назад после удаления.
    </>,
    <>
      <b>Important:</b> you won't be able to bring the box back after deletion.
    </>,
  ),
  box_has_user_without_wards: _(
    <>
      <b>Внимание! </b> <br />У одого из участников нет подопечного и Тайного Санты, так как он был
      добавлен после проведения жеребьевки. Перейдите на страницу жеребьевки, чтобы решить эту
      проблему.
    </>,
    <>
      <b>Attention!</b>
      <br /> One of the participants doesn't have a ward or Secret Santa, as they were added after
      the drawing. Go to the drawing page to resolve this issue.
    </>,
  ),
  box_has_users_without_wards: _(
    <>
      <b>Внимание! </b>
      <br /> У нескольких участников нет подопечного и Тайного Санты, так как они были добавлены
      после проведения жеребьевки. Перейдите на страницу жеребьевки, чтобы решить эту проблему.
    </>,
    <>
      <b>Attention!</b>
      <br />
      Several participants don't have wards or Secret Santas because they were added after the
      drawing. Please go to the drawing page to resolve this issue.
    </>,
  ),

  box_is_invite_after_draw_hint: _(
    <>
      Вы выставили опцию <b>Отправить приглашения вместе с результатами жеребьевки</b> поэтому
      письма участникам пока что не были отправлены. Письма-приглашения отправятся сразу после
      проведения жеребьевки.
    </>,
    <>
      You have selected the option <b>Send invitations along with the drawing results</b>, so the
      emails to the participants have not been sent yet. The invitation emails will be sent
      immediately after the drawing is completed.
    </>,
  ),

  box_not_authorized_hint: _(
    (isLoggedIn) => {
      return (
        <>
          <RowCenter>
            <b>Нет доступа к коробке</b>
          </RowCenter>
          <br />
          Доступ к коробке возможен только зарегистрированным или приглашенным участникам.
          <br />
          <br />
          <b>Вы хотите создать карточку участника?</b>
          <br />
          <br />
          Вам нужно перейти на сайт по пригласительной ссылке, которая выглядит примерно так:{' '}
          <b>https://santa-secret.ru/box/example/card?join=J0InS3cre7S4n74</b>. Если организатор
          прислал вам ссылку, которая переводит вас на данный экран, то сообщите ему, что это
          неверная ссылка - пусть скопирует верную ссылку со страницы добавления участников в
          коробке и отправит вам.
          <br />
          <br />
          <b>Вы уже были зарегистрированы в данной коробке?</b>
          <br />
          <br />
          Если вы уже создавали карточку участника в коробке, но попали на данный экран, это значит,
          что вы не зашли на сайт под аккаунтом, с которым вы создавали карточку участника.{' '}
          <SimpleLink href={'/login'}>
            <TextMedium orange underline>
              Войдите на сайт
            </TextMedium>
          </SimpleLink>{' '}
          под тем же аккаунтом, с которым вы регистрировались в коробке. Помните, что вы могли
          использовать соц. сети для входа.
          <br />
          <br />
          Если вы <i>не помните</i>, как вы регистрировались - свяжитесь с организатором и попросите
          его посмотреть, какой email указан у вас в карточке. Если там указан email - зайдите под
          ним. Если в карточке ничего не указано - это значит что вы заходили с помощью VKontakte
          или Telegram - попробуйте зайти. Если в карточке указана неверная почта - попросите вашего
          организатора изменить ее, тогда вам придет письмо со ссылкой уже на верную почту.
          <br />
          <br />
          <b>Вы перешли по ссылке из письма и попали сюда?</b>
          <br />
          <br />
          Если вы попали на этот экран, перейдя по ссылка из письма регистрации, это может означать,
          что ваша карточка была удалена из коробки. Обратитесь к вашему организатору.
          <br />
          <br />
        </>
      );
    },
    (isLoggedIn) => {
      return (
        <>
          <RowCenter>
            <b>No access to the box</b>
          </RowCenter>
          <br />
          Access to the box is possible only for registered or invited participants.
          <br />
          <br />
          <b>Do you want to create a participant card?</b>
          <br />
          <br />
          You need to go to the site via an invitation link, which looks something like this:{' '}
          <b>https://santa-secret.com/box/example/card?join=J0InS3cre7S4n74</b>. If the organizer
          sent you a link that brings you to this screen, tell them that it is the wrong link - they
          should copy the correct link from the page for adding participants in the box and send it
          to you.
          <br />
          <br />
          <b>Have you already been registered in this box?</b>
          <br />
          <br />
          If you have already created a participant card in the box but have come to this screen, it
          means that you have not logged into the site with the account with which you created the
          participant card.{' '}
          <SimpleLink href={'/login'}>
            <TextMedium orange underline>
              Log into the site
            </TextMedium>
          </SimpleLink>{' '}
          with the same account you registered with in the box. Remember, you could have used social
          networks to log in.
          <br />
          <br />
          If you <i>do not remember</i> how you registered - contact the organizer and ask them to
          check what email is listed on your card. If an email is listed - log in with it. If
          nothing is specified in the card - it means you logged in using Facebook or Telegram - try
          logging in. If the card lists the wrong email - ask your organizer to change it, then you
          will receive an email with a link to the correct email.
          <br />
          <br />
          <b>Did you follow the link from the email and end up here?</b>
          <br />
          <br />
          If you came to this screen by following a link from a registration email, it may mean that
          your card was removed from the box. Contact your organizer.
          <br />
          <br />
        </>
      );
    },
  ),

  box_table_hint: _(
    (drawActive, num) => (
      <>
        На этой странице показан актуальный список участников со всей информацией.
        {num > 150 ? (
          <>
            В коробке слишком много участников, поэтому <b>отображаются только первые 150</b>, но вы
            можете скачать таблицу для просмотра информации по всем участникам.
            <br />
          </>
        ) : (
          ''
        )}
        <br />
        <b>Если таблица не отображается корректно, откройте её с помощью google sheets.</b>
        {drawActive ? (
          <></>
        ) : (
          <>
            <br />
            <br />
            Жеребьевка пока что не проведена, поэтому у участников не отображаются подопечные, Санты
            и информация по подаркам. Когда вы проведете жеребьевку, информация обновится.
          </>
        )}
      </>
    ),
    (drawActive, num) => (
      <>
        This page displays the current list of participants with all the information.
        {num > 150 ? (
          <>
            There are too many participants in the box, so <b>only the first 150 are displayed</b>,
            but you can download the table to view information about all participants.
            <br />
          </>
        ) : (
          ''
        )}
        <br /> <b>If the table is not displayed correctly, open it with Google Sheets.</b>
        {drawActive ? (
          <></>
        ) : (
          <>
            <br />
            <br />
            The draw has not been conducted yet, so participants do not have their wards, Santas,
            and gift information displayed. When you conduct the draw, the information will be
            updated.
          </>
        )}
      </>
    ),
  ),
  box_table_header_number: _('№', '#'),
  box_table_header_name: _('Имя', 'Name'),
  box_table_header_ward: _('Подопечный', 'Giftee'),
  box_table_header_gift_sent: _('Подарок отправлен', 'Gift Sent'),
  box_table_header_santa: _('Тайный Санта', 'Secret Santa'),
  box_table_header_gift_received: _('Подарок получен', 'Gift Received'),
  box_table_header_email: _('Email', 'Email'),
  box_table_header_phone: _('Телефон', 'Phone Number'),
  box_table_header_postal_address: _('Почтовый адрес', 'Mailing Address'),
  box_table_header_wish: _('Пожелания', 'Wish'),
  box_table_show: _('Показать', 'Show'),
  box_table_no_ward: _('Нет подопечного', 'No drawn name'),
  box_table_know_ward: _('Знает подопечного', 'Knows giftee'),
  box_table_no_santa: _('Нет Санты', 'No Santa'),
  box_table_no_phone: _('Нe указан', 'No phone number'),
  box_table_no_email: _('Нe указан', 'No email'),
  box_table_no_wish: _('Нет пожеланий', 'No wish'),
  box_table_no_postal_address: _('Не указан', 'No address yet'),
  box_table_edit: _('Редактировать', 'Edit'),
  box_table_download: _('Скачать таблицу', 'Download'),
  box_admin_did_not_add_email: _(
    'Вы не указали email адрес для связи - участники не смогут обратиться к вам с вопросами по игре. Пожалуйста, укажите email адрес в настройках профиля.',
    "You did not provide an email address for contact - participants won't be able to reach out to you with game-related questions. Please specify your email address in your profile settings.",
  ),
  box_admin_add_email: _('Указать email', 'Provide Email'),
  box_org_message_sent: _('Сообщение было отправлено', 'Message has been sent'),

  box_toggle_menu_participants: _('Участники', 'Participants'),
  box_toggle_menu_card: _('Моя карточка', 'My card'),
  box_toggle_menu_ward: _('Подопечный', 'Giftee'),
  box_table_title: _('Таблица участников', 'Participants Table'),

  box_no_giftee: _('Нет подопечного', 'No giftee'),
  box_knows_giftee: _('Знает кому дарить', 'Knows giftee'),
  box_does_not_know_giftee: _('Еще не знает кому дарить', 'Does not know giftee yet'),
  box_your_giftee: _('Ваш подопечный', 'Your giftee'),
  box_gift_received: _('Подарок получен', 'Gift received'),
  box_gift_was_sent: _('Подарок в пути', 'Gift on the way'),
  box_wait_for_draw: _('Ожидает проведения жеребьевки', 'Waiting for the draw'),

  box_it_is_you: _('Это вы', 'It is you'),
  box_wait_for_filling: _(
    'Ожидается подтверждение участия ',
    'Awaiting participation confirmation ',
  ),
  box_wait_for_filling_what_is_it: _('что это?', 'what is it?'),
};
