import * as React from 'react';
import { getCookie } from '@src/utils/cookie';
import { isDotCom } from '@src/host';
import { SantaAppState } from '@src/state';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { reachGoalYaMetrika } from '@src/utils/metrika';

const YandexBannerComponent = ({ mobile }: { mobile: boolean }) => {
  //  ulock val: U2FsdGVkX1%2FLj9lLq46TpIKVyGVodk6%2BIOPhr20%2FPFw%3D
  // || getCookie('yandex_ad') !== 'unlock'
  if (isDotCom()) {
    return null;
  }
  useEffect(() => {
    reachGoalYaMetrika('YandexBannerShown');
    if (mobile) {
      reachGoalYaMetrika(`YandexBannerShown_Mobile`);
    } else {
      reachGoalYaMetrika('YandexBannerShown_Desktop');
    }
  }, []);
  let img = 'https://santa-secret.b-cdn.net/yandex_banner_desktop.png';
  if (mobile) {
    img = 'https://santa-secret.b-cdn.net/yandex_banner_mobile.png';
  }
  const link =
    'https://nquw.adj.st/my/wishlist/lists?adj_t=1jc5qhku&adj_redirect=https%3A%2F%2Fmarket.yandex.ru%2Fmy%2Fwishlist%2Flists%3Fclid%3D1660%26utm_source%3Dsecret_santa%26utm_campaign%3Dsecret_santa2024';
  return (
    <a
      href={link}
      target="_blank"
      style={{ cursor: 'pointer !important' }}
      onClick={() => {
        reachGoalYaMetrika('YandexBannerClicked');
      }}
    >
      <img style={{ width: '100%', marginBottom: '1rem', cursor: 'pointer' }} src={img} />
    </a>
  );
};

function mapStateToProps(state: SantaAppState) {
  return {
    mobile: state.css.mobile,
  };
}

export const YandexBanner = connect(mapStateToProps)(YandexBannerComponent);
